.App {
  text-align: center;
}

body{
  background-image: url("./assets/bg.png") !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media only screen and (max-width: 1020px) {
  body{
    background: #1d1e22;
  }
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.buttons-group{
  display:flex;
  justify-content:center;
  align-items:center
}
.buttons-group .btn-retry{
  margin: 15px 10px 0 10px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
